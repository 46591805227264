import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { FaAngleRight,FaCalendarAlt } from "react-icons/fa";
import Link from "../widgets/ScrollToTopLink";
import moment from "moment";
import "moment/locale/es-mx";

export default function BlogCard(post, props) {
    const { fullHeader } = props;
    moment.locale('es');

    return (
        <Row>
            <Col lg={5} sm={12} md={12} style={{ cursor: 'default' }}>
                <Header full={fullHeader}  {...post} />
            </Col>
            <Col lg={7} sm={12} md={12}>
                <h5 className="style-titulo-blog">
                    {post.title}{""}
                </h5>
                <span className="style-fecha-blog">
                    <div style={{display: 'inline-block'}}> 
                        <FaCalendarAlt size={15} />
                    </div>
                    <p className="text-fecha">
                        {moment(post.date).format('LL')}{""}
                    </p>
                </span>
                <p className="style-texto-blog">
                    {post.description}{""}
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer'}}>
                        <Button as={Link} to={`/rivero/blog/${post.url}`}>
                            Leer mas .... <div style={{ color: "white", display: 'inline-block'}}> <FaAngleRight size={17} /></div>
                        </Button>
                </div>
            </Col>
        </Row>
    )

}

function Header({ image, title }) {

    return (
        <Card.Header>
            <picture>
                {/* <source srcSet={`${webp300} 1.0x, ${webp600} 2.0x`} type="image/webp"/>
                <source srcSet={`${png300} 1.0x, ${png600} 2.0x`} type="image/png" /> */}
                <img
                    src={image}
                    alt={title + ` - Noticias`}
                    style={{ width: '100%' }}
                />
            </picture>
        </Card.Header>
    )

}