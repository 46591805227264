import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../../components/seo";
import api from "../../actions/riveroApi";
import Layout from "../../components/layouts/layout";
import FrameFacebook from "../../components/widgets/FrameFacebook";
import CatalogoLayoutBlogs from "../../components/layouts/CatalogoLayoutBlogs";
import PaginationApi from "../../components/paginacion/PaginacionApi";

export default function IndexBlogs(props){
    const parameters = props.location.search;

    const [blogs, setBlogs] = useState([]);
    const [metaData, setMetaData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ links, setLinks ] = useState(null);
    const path = props.location.pathname.split("/");
    const [pageNumber, setPageNumber] = useState(path[2] ? parseInt(path[2]) : 1);

    const [ splitMarca, setSplitMarca ] = useState();

    useEffect(() => {

        let splitUrl = window.location.pathname.split("/");
        console.log("SplitUtl, ", splitUrl[1]);
        setSplitMarca(splitUrl[1]);
        setIsLoading(true);
        api.getBlogs({ page: pageNumber, orderBy: "fecha:desc", marca: "nissan" }).then((resp) => {
            console.log(resp.data);
            setIsLoading(false);
            setMetaData(resp.meta);
            setBlogs(resp.data);
            setLinks(resp.links)
        }).catch((error) => console.log(error));

    }, [pageNumber]);

    return(
        <Layout parameters={parameters}>
            <SEO
                title="Nissan Rivero Monterrey - Entradas del blog"
                description={
                "Nissan Rivero - Agencia Nissan Monterrey March, Versa, " +
                "Sentra, Altima, Kicks, XTrail - venta autos nuevos y " +
                "seminuevos - Nissan Las Torres, Nissan Contry, Nissan Valle"
                }
                keywords="Agencia Nissan Monterrey"
                path={`/blog`}
            />
            <Container className="p-4 bg-white">
                <Row>
                    <Col lg={8} sm={12} md={8}>
                        <h1>Entradas del blog</h1>
                        <div className="pt-4">
                            <CatalogoLayoutBlogs post={blogs} isLoading={isLoading} marca={splitMarca}/>
                            <hr/>
                            <PaginationApi
                                metaData={metaData}
                                links={links}
                                pagina="rivero/blogs"
                                onChangePage={setPageNumber}
                                pageNumber={pageNumber}
                            />
                        </div>
                    </Col>
                    <Col lg={4} sm={12} md={3}>
                        <FrameFacebook marca={"nissan"}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}