import React from "react"
import PropType from "prop-types"
import Link from '../widgets/ScrollToTopLink';

export default function PaginationApi(props) {
  console.log(props, "PORPS");
  const { metaData, onChangePage } = props;
  const pagina = "/" + props.pagina
  const item = []

  if (!metaData) {
    return null;
  }

  const numberOfPages = parseInt(Math.ceil(metaData.total / metaData.per_page));
  const ultimapagina = "/" + props.pagina + "/" + numberOfPages;
  const nextPagePath = props.pageNumber < numberOfPages ? `/${props.pagina}/${props.pageNumber + 1}` : '';
  const previousPagePath = metaData.current_page >= 2 ? `/${props.pagina}` : `/${props.pagina}/${metaData.current_page - 1}`;
console.log(previousPagePath, "Pade")
  for (let i = 1; i < numberOfPages + 1; i++) {
    let ultimapaginaNum = ``;
    const isCurrent = i === props.pageNumber;

    if (i === 1) {
      ultimapaginaNum = "/" + props.pagina + "/";
    } else {
      ultimapaginaNum = "/" + props.pagina + "/" + i;
    }

    item.push(
      <Link
        to={ultimapaginaNum}
        aria-current={isCurrent ? "true" : "false"}
        aria-disabled="false"
        type="pageItem"
        className={`item-numeros${isCurrent ? ' activoColor' : ''}`}
        key={i}
        onClick={() => {
          onChangePage(i)
        }}
      >
        {i}
      </Link>
    );
  }

  
  return (
    <center>
      <div
        aria-label="Pagination Navigation"
        role="navigation"
        className="paginacion"
      >
        <Link
          to={pagina}
          aria-current="false"
          aria-disabled="false"
          value="1"
          aria-label="First item"
          type="firstItem"
          className="item-paginacion"
          onClick={() => {
            onChangePage(1)
          }}
        >
          «
        </Link>

        {previousPagePath && (
          <Link
            to={previousPagePath}
            aria-current="false"
            aria-disabled="false"
            value="4"
            aria-label="Previous item"
            type="prevItem"
            className="item-paginacion"
            onClick={() => {
              onChangePage(previousPagePath)
            }}
          >
            ⟨
          </Link>
        )}

        {item}

        {nextPagePath && (
          <Link
            to={nextPagePath}
            aria-current="false"
            aria-disabled="true"
            value="4"
            aria-label="Previous item"
            type="prevItem"
            className="item-paginacion"
            onClick={() => {
              onChangePage(props.pageNumber + 1)
            }}
          >
            ⟩
          </Link>
        )}

        <Link
          to={ultimapagina}
          aria-current="false"
          aria-disabled="false"
          value="1"
          aria-label="Last item"
          type="lastItem"
          className="item-paginacion"
          onClick={() => {
            onChangePage(numberOfPages)
          }}
        >
          »
        </Link>
      </div>
    </center>
  )
}
PaginationApi.protoType = {
  pageContext: PropType.object.isRequired,
}