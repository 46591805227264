import React from 'react';
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';

export default function ScrolToTopLink(props) {
  const handleClick = (e) => {
    props.onClick && props.onClick(e);
    //setTimeout(() => scrollTo('#container2'), 25);
  };

  return (
    <Link to="" {...props} onClick={handleClick}>
      {props.children}
    </Link>
  );
}